
import Nav from "../components/PortfolioNav";

import '../assets/css/portfolio.css'



export default function Project() {
  return (
    <main >
        <div>
          <Nav/>
        </div>
    </main>
  );
}


